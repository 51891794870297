import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./footer.scss";
import { logo } from "images";

const Footer = () => {
  return (
    <>
      <footer className="pt-5">
        <Container className="pt-4">
          <Row className="row-cols-md-6">
            <Col md={4} className="mb-4">
              <div className="footer-about">
                <div className="d-flex flex-column flex-md-row align-items-start">
                  <img src={logo} alt="" className="me-md-4 mb-3 mb-md-0" />
                  <div>
                    <h5>
                      LUMAPIX<sup>TM</sup>
                    </h5>
                    <p>
                      84, Ajanta Path, Beltola, <br /> Guwahati 24, Assam
                    </p>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={8}>
              <Row className="justify-content-end">
                <Col md={3} className="mb-5">
                  <div className="footer-column">
                    <div className="footer-heading">
                      <h5>Contact Us</h5>
                    </div>
                    <ul className="list-unstyled">
                      <li>
                        <a href="mailto:Info@lumapix.net">Email</a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/lumapixels/"  target="_blank">Company</a>
                      </li>
                      <li>
                        <a href="mailto:hrlumapixels@gmail.com">Join Us</a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={4} className="mb-5">
                  <div className="footer-column">
                    <div className="footer-heading">
                      <h5>Social Media</h5>
                    </div>
                    <ul className="list-unstyled d-flex social-media">
                      <li className="ms-2">
                        <a
                          href="https://www.facebook.com/share/1BnrH6vzd1/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="ms-2">
                        <a
                          href="https://www.linkedin.com/company/lumapixels/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li className="ms-2">
                        <a
                          href="https://www.instagram.com/lumapix.official"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={12}>
              <p className="text-center">
                All Rights Reserved by Lumapix Creative Studios LLP 2025
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
